import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useTheme } from '../../context/ThemeContext';
import { FiBook, FiTarget, FiCode, FiHeart, FiUsers, FiMail } from 'react-icons/fi';

const AboutSection = styled.section`
  padding: 120px 0;
  background: ${props => props.isDarkMode ? '#1a1a2e' : '#f8f9fa'};
  transition: background-color 0.3s ease;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.8rem;
  color: ${props => props.isDarkMode ? '#ffffff' : '#1a1a2e'};
  margin-bottom: 4rem;
  font-weight: 700;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: #00bfa5;
    border-radius: 2px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  color: ${props => props.isDarkMode ? '#ffffff' : '#1a1a2e'};
`;

const Section = styled.div`
  background: ${props => props.isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'white'};
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
  }
`;

const SectionTitle = styled.h3`
  font-size: 1.8rem;
  color: #00bfa5;
  margin-bottom: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    font-size: 1.5rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  opacity: 0.9;
  color: ${props => props.isDarkMode ? 'rgba(255, 255, 255, 0.9)' : '#333'};
`;

const About = () => {
  const { i18n } = useTranslation();
  const { isDarkMode } = useTheme();

  const sections = [
    { id: 'history', icon: <FiBook /> },
    { id: 'mission', icon: <FiTarget /> },
    { id: 'expertise', icon: <FiCode /> },
    { id: 'values', icon: <FiHeart /> },
    { id: 'team', icon: <FiUsers /> },
    { id: 'cta', icon: <FiMail /> }
  ];

  return (
    <AboutSection id="about" isDarkMode={isDarkMode}>
      <Container>
        <Title isDarkMode={isDarkMode}>{i18n.t('about.title')}</Title>
        <Content isDarkMode={isDarkMode}>
          {sections.map(({ id, icon }) => (
            <Section key={id} isDarkMode={isDarkMode}>
              <SectionTitle isDarkMode={isDarkMode}>
                {icon}
                {i18n.t(`about.${id}.title`)}
              </SectionTitle>
              <Paragraph isDarkMode={isDarkMode}>
                {i18n.t(`about.${id}.content`)}
              </Paragraph>
            </Section>
          ))}
        </Content>
      </Container>
    </AboutSection>
  );
};

export default About; 