import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import backgroundImage from '../../assets/backgroundPhoto.jpg';

const HeroContainer = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.6) 100%
  ),
  url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  scroll-margin-top: 100px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at center,
    transparent 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  z-index: -1;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  animation: fadeIn 1s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Title = styled.h1`
  font-size: 4rem;
  margin-bottom: 2rem;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  
  span {
    color: #00bfa5;
    text-shadow: 2px 2px 8px rgba(0, 191, 165, 0.3);
  }
`;

const Subtitle = styled.p`
  font-size: 1.8rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 3rem;
  line-height: 1.8;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 2rem;
`;

const Button = styled.button`
  padding: 1.2rem 3rem;
  font-size: 1.1rem;
  background: ${props => props.primary ? 'rgba(0, 191, 165, 0.9)' : 'transparent'};
  color: white;
  border: ${props => props.primary ? 'none' : '2px solid rgba(255, 255, 255, 0.8)'};
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s ease;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  backdrop-filter: blur(5px);
  position: relative;
  overflow: hidden;

  &:hover {
    background: ${props => props.primary ? '#00bfa5' : 'rgba(255, 255, 255, 0.15)'};
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(-1px);
  }
`;

const PreviewOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  opacity: ${props => props.show ? 1 : 0};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
`;

const PreviewContent = styled.div`
  transform: translateY(${props => props.show ? '0' : '100px'});
  opacity: ${props => props.show ? 1 : 0};
  transition: all 0.5s ease;
  padding: 2rem;
  color: white;
  text-align: center;
`;

const Hero = () => {
  const { i18n } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      if (showPreview) {
        setShowPreview(false);
        setTimeout(() => {
          section.scrollIntoView({ behavior: 'smooth' });
        }, 300);
      } else {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleServicesClick = () => {
    setShowPreview(true);
    setTimeout(() => {
      scrollToSection('services');
    }, 1000);
  };

  const handleContactClick = () => {
    scrollToSection('contact');
  };

  return (
    <>
      <HeroContainer id="home">
        <Overlay />
        <HeroContent>
          <Title>
            {i18n.t('hero.title')}
            <span>.</span>
          </Title>
          <Subtitle>{i18n.t('hero.subtitle')}</Subtitle>
          <ButtonGroup>
            <Button primary onClick={handleServicesClick}>
              {i18n.t('hero.services_button')}
            </Button>
            <Button onClick={handleContactClick}>
              {i18n.t('hero.contact_button')}
            </Button>
          </ButtonGroup>
        </HeroContent>
      </HeroContainer>

      <PreviewOverlay show={showPreview} onClick={() => setShowPreview(false)}>
        <PreviewContent show={showPreview}>
          <h2 style={{ fontSize: '2rem', marginBottom: '1rem' }}>
            {i18n.t('services.title')}
          </h2>
          <p style={{ fontSize: '1.2rem', maxWidth: '600px' }}>
            {i18n.t('services.preview')}
          </p>
        </PreviewContent>
      </PreviewOverlay>
    </>
  );
};

export default Hero;