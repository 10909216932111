import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FiMonitor, FiSmartphone, FiCode } from 'react-icons/fi';
import { useTheme } from '../../context/ThemeContext';

const ServicesSection = styled.section`
  padding: 80px 0;
  background: ${props => props.isDarkMode ? '#1a1a2e' : '#ffffff'};
  scroll-margin-top: 100px;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  color: ${props => props.isDarkMode ? '#ffffff' : '#333'};
  margin-bottom: 50px;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
`;

const ServiceCard = styled.div`
  text-align: center;
  padding: 30px;
  background: ${props => props.isDarkMode ? 'rgba(255, 255, 255, 0.05)' : '#fff'};
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }

  svg {
    font-size: 3rem;
    color: #00bfa5;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.5rem;
    color: ${props => props.isDarkMode ? '#ffffff' : '#333'};
    margin-bottom: 15px;
  }

  p {
    color: ${props => props.isDarkMode ? 'rgba(255, 255, 255, 0.8)' : '#666'};
    line-height: 1.6;
  }
`;

const Services = () => {
  const { i18n } = useTranslation();
  const { isDarkMode } = useTheme();

  return (
    <ServicesSection id="services" isDarkMode={isDarkMode}>
      <Container>
        <Title isDarkMode={isDarkMode}>{i18n.t('services.title')}</Title>
        <ServicesGrid>
          <ServiceCard isDarkMode={isDarkMode}>
            <FiMonitor />
            <h3>{i18n.t('services.web.title')}</h3>
            <p>{i18n.t('services.web.description')}</p>
          </ServiceCard>
          <ServiceCard isDarkMode={isDarkMode}>
            <FiSmartphone />
            <h3>{i18n.t('services.mobile.title')}</h3>
            <p>{i18n.t('services.mobile.description')}</p>
          </ServiceCard>
          <ServiceCard isDarkMode={isDarkMode}>
            <FiCode />
            <h3>{i18n.t('services.consulting.title')}</h3>
            <p>{i18n.t('services.consulting.description')}</p>
          </ServiceCard>
        </ServicesGrid>
      </Container>
    </ServicesSection>
  );
};

export default Services;