import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          brand: {
            name: 'NeoPlus'
          },
          common: {
            changeLang: 'Change Language',
            lightMode: 'Switch to Light Mode',
            darkMode: 'Switch to Dark Mode',
            menu: 'Menu'
          },
          hero: {
            title: 'PROFESSIONAL SOFTWARE SOLUTIONS',
            subtitle: 'We provide innovative and customized software solutions to take your business one step forward in the digital world.',
            services_button: 'OUR SERVICES',
            contact_button: 'CONTACT US'
          },
          contact: {
            title: 'Contact Us'
          },
          services: {
            title: 'Our Services',
            preview: 'Scroll down for modern web applications, mobile solutions and more...',
            web: {
              title: 'Web Development',
              description: 'Modern and responsive web applications'
            },
            mobile: {
              title: 'Mobile Development',
              description: 'Native mobile applications for iOS and Android'
            },
            consulting: {
              title: 'Software Consulting',
              description: 'Professional software consulting services'
            }
          },
          about: {
            title: 'About Us',
            history: {
              title: 'Company History and Mission',
              content: 'Founded in 2024 with the mission to bring innovative solutions to the software industry, NEOPLUS INFORMATION TECHNOLOGY has quickly become a leader in the rapidly growing technology sector. Since our founding, we have been supporting our customers by developing customized software solutions to improve their business processes.'
            },
            mission: {
              title: 'Mission and Vision',
              content: 'Our mission is to facilitate digital transformation processes for businesses by developing innovative technologies and user-friendly software. Our vision is to become a recognized software company in the world and provide sustainable solutions in the digital world.'
            },
            expertise: {
              title: 'Company Expertise Areas',
              content: 'Our team specializes in mobile app development, web solutions, artificial intelligence, and data analytics. We bring value to our customers by leveraging our experience in various sectors such as education, healthcare, and e-commerce.'
            },
            values: {
              title: 'Values and Principles',
              content: 'Innovation, customer focus, and team spirit are the fundamental pillars of our company culture. We aim to make our customers\' business processes more efficient by using technology in a human-centric way.'
            },
            team: {
              title: 'Team and Work Culture',
              content: 'Our dynamic team of experts, designers, and project managers pushes the boundaries of technology. Our belief in teamwork and continuous learning allows us to deliver the best solutions to our customers.'
            },
            cta: {
              title: 'Contact Us',
              content: 'Contact us to take your business to the next level in the digital world. We would be happy to create customized solutions for you!'
            }
          },
          nav: {
            home: 'Home',
            about: 'About Us',
            services: 'Services',
            contact: 'Contact'
          },
          'scroll-text': 'Scroll down for modern web applications, mobile solutions and more...'
        }
      },
      tr: {
        translation: {
          brand: {
            name: 'NeoPlus'
          },
          common: {
            changeLang: 'Dil Değiştir',
            lightMode: 'Açık Temaya Geç',
            darkMode: 'Koyu Temaya Geç',
            menu: 'Menü'
          },
          hero: {
            title: 'PROFESYONEL YAZILIM ÇÖZÜMLERİ',
            subtitle: 'İşletmenizi dijital dünyada bir adım öne taşıyacak yenilikçi ve özelleştirilmiş yazılım çözümleri sunuyoruz.',
            services_button: 'HİZMETLERİMİZ',
            contact_button: 'İLETİŞİME GEÇİN'
          },
          contact: {
            title: 'İletişim'
          },
          services: {
            title: 'Hizmetlerimiz',
            preview: 'Modern web uygulamaları, mobil çözümler ve daha fazlası için aşağı kaydırın...',
            web: {
              title: 'Web Geliştirme',
              description: 'Modern ve responsive web uygulamaları geliştiriyoruz'
            },
            mobile: {
              title: 'Mobil Uygulama',
              description: 'iOS ve Android için native mobil uygulamalar'
            },
            consulting: {
              title: 'Yazılım Danışmanlığı',
              description: 'Profesyonel yazılım danışmanlığı hizmetleri'
            }
          },
          about: {
            title: 'Hakkımızda',
            history: {
              title: 'Şirketin Tarihçesi ve Kuruluş Amacı',
              content: '2024 yılında yazılım dünyasına yenilikçi çözümler sunmak amacıyla kurulan NEOPLUS INFORMATION TECHNOLOGY, hızla büyüyen teknoloji sektöründe fark yaratmayı hedefliyor. Kurulduğumuz günden bu yana, müşterilerimizin ihtiyaçlarına özel yazılım çözümleri geliştirerek iş süreçlerini iyileştirmelerine destek oluyoruz.'
            },
            mission: {
              title: 'Misyon ve Vizyon',
              content: 'Misyonumuz, yenilikçi teknolojiler ve kullanıcı dostu yazılımlar geliştirerek işletmelerin dijital dönüşüm süreçlerini kolaylaştırmaktır. Vizyonumuz ise, dünya çapında tanınan bir yazılım firması olarak sektöre liderlik etmek ve dijital dünyada sürdürülebilir çözümler sunmaktır.'
            },
            expertise: {
              title: 'Şirketin Uzmanlık Alanları',
              content: 'Mobil uygulama geliştirme, web çözümleri, yapay zeka ve veri analitiği alanlarında uzmanlaşan ekibimiz, işletmelere özel yenilikçi projeler sunmaktadır. Eğitim, sağlık ve e-ticaret gibi farklı sektörlerdeki deneyimimizle müşterilerimize değer katıyoruz.'
            },
            values: {
              title: 'Değerler ve İlkeler',
              content: 'Yenilikçilik, müşteri odaklılık ve takım ruhu, şirket kültürümüzün temel taşlarını oluşturur. Teknolojiyi insan odaklı bir şekilde kullanarak, müşterilerimizin iş süreçlerini daha verimli hale getirmeyi hedefliyoruz.'
            },
            team: {
              title: 'Ekip ve Çalışma Kültürü',
              content: 'Uzman mühendislerden, tasarımcılardan ve proje yöneticilerinden oluşan dinamik ekibimizle, teknolojinin sınırlarını zorluyoruz. Takım ruhuna ve sürekli öğrenmeye olan inancımız sayesinde müşterilerimize en iyi çözümleri sunuyoruz.'
            },
            cta: {
              title: 'İletişim Çağrısı',
              content: 'İşinizi dijital dünyada bir adım öne taşımak için bizimle iletişime geçin. Size özel çözümler üretmekten mutluluk duyarız!'
            }
          },
          nav: {
            home: 'Ana Sayfa',
            about: 'Hakkımızda',
            services: 'Hizmetler',
            contact: 'İletişim'
          },
          'scroll-text': 'Modern web uygulamaları, mobil çözümler ve daha fazlası için aşağı kaydırın...'
        }
      },
      ar: {
        translation: {
          brand: {
            name: 'نيوبلس'
          },
          common: {
            changeLang: 'تغيير اللغة',
            lightMode: 'الوضع الفاتح',
            darkMode: 'الوضع الداكن',
            menu: 'القائمة'
          },
          hero: {
            title: 'حلول برمجية احترافية',
            subtitle: 'نقدم حلولاً برمجية مبتكرة ومخصصة لنقل عملك خطوة للأمام في العالم الرقمي',
            services_button: 'خدماتنا',
            contact_button: 'اتصل بنا'
          },
          contact: {
            title: 'اتصل بنا'
          },
          services: {
            title: 'خدماتنا',
            preview: 'انتقل لأسفل للاطلاع على تطبيقات الويب الحديثة والحلول المتنقلة والمزيد...',
            web: {
              title: 'تطوير الويب',
              description: 'تطوير تطبيقات ويب حديثة ومتجاوبة'
            },
            mobile: {
              title: 'تطوير تطبيقات الموبايل',
              description: 'طبيقات أصلية لنظامي iOS و Android'
            },
            consulting: {
              title: 'استشارات برمجية',
              description: 'خدمات استشارية برمجية احترافية'
            }
          },
          about: {
            title: 'من نحن',
            history: {
              title: 'تاريخ الشركة ورسالتها',
              content: 'تأسست NEOPLUS INFORMATION TECHNOLOGY في عام 2024 بهدف تقديم حلول مبتكرة لصناعة البرمجيات'
            },
            mission: {
              title: 'المهمة والرؤية',
              content: 'مهمتنا هي تسهيل عمليات التحول الرقمي للشركات من خلال تطوير تقنيات مبتكرة وبرامج سهلة الاستخدام'
            },
            expertise: {
              title: 'مجالات خبرتنا',
              content: 'يتخصص فريقنا في تطوير تطبيقات الموبايل وحلول الويب والذكاء الاصطناعي وتحليل البيانات'
            },
            values: {
              title: 'قيمنا ومبادئنا',
              content: 'الابتكار والتركيز على العملاء وروح الفريق هي الركائز الأساسية لثقافة شركتنا'
            },
            team: {
              title: 'الفريق وثقافة العمل',
              content: 'فريقنا الديناميكي من الخبراء والمصممين ومديري المشاريع يدفع حدود التكنولوجيا'
            },
            cta: {
              title: 'اتصل بنا',
              content: 'اتصل بنا لنقل عملك إلى المستوى التالي في العالم الرقمي'
            }
          },
          nav: {
            home: 'الرئيسية',
            about: 'من نحن',
            services: 'خدماتنا',
            contact: 'اتصل بنا'
          },
          'scroll-text': 'Scroll down for modern web applications, mobile solutions and more...'
        }
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n; 