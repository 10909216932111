import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FiSun, FiMoon } from 'react-icons/fi';
import { useTheme } from '../../context/ThemeContext';
import logo from '../../assets/logo.jpg';

const HeaderContainer = styled.header`
  background: ${props => props.isDarkMode ? 'rgba(26, 26, 46, 0.9)' : 'rgba(255, 255, 255, 0.9)'};
  padding: 0.5rem 2rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Nav = styled.nav`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  height: 120px;
  width: 180px;
  object-fit: contain;
  transition: transform 0.3s ease;
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 3rem;
`;

const NavLink = styled.a`
  color: ${props => props.isDarkMode ? 'white' : '#1a1a2e'};
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  position: relative;
  padding: 5px 0;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: #00bfa5;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #00bfa5;
    transform: translateY(-2px);

    &::after {
      width: 100%;
    }
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const LanguageSelector = styled.div`
  display: flex;
  gap: 1rem;
`;

const LangButton = styled.button`
  background: transparent;
  border: 1px solid ${props => props.active ? '#00bfa5' : (props.isDarkMode ? 'white' : '#1a1a2e')};
  color: ${props => props.active ? '#00bfa5' : (props.isDarkMode ? 'white' : '#1a1a2e')};
  padding: 0.5rem 1.2rem;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background: ${props => props.isDarkMode ? 'rgba(0, 191, 165, 0.2)' : 'rgba(0, 191, 165, 0.1)'};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s ease-out;
    z-index: -1;
  }

  &:hover {
    transform: translateY(-2px);
    border-color: #00bfa5;
    color: ${props => props.isDarkMode ? '#00bfa5' : '#008c7a'};
    box-shadow: 0 5px 15px rgba(0, 191, 165, 0.2);

    &::before {
      width: 200px;
      height: 200px;
    }
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 8px rgba(0, 191, 165, 0.2);
  }
`;

const ThemeToggle = styled.button`
  background: transparent;
  border: none;
  color: ${props => props.isDarkMode ? 'white' : '#1a1a2e'};
  cursor: pointer;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background: ${props => props.isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 191, 165, 0.1)'};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease-out;
  }

  &:hover {
    color: #00bfa5;
    transform: rotate(180deg) scale(1.1);

    &::before {
      width: 100%;
      height: 100%;
    }
  }

  &:active {
    transform: rotate(180deg) scale(0.95);
  }
`;

const Header = () => {
  const { i18n } = useTranslation();
  const { isDarkMode, toggleTheme } = useTheme();
  const isArabic = i18n.language === 'ar';

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <HeaderContainer isDarkMode={isDarkMode}>
      <Nav>
        <Logo 
          src={logo} 
          alt="NeoPlus" 
          onClick={() => scrollToSection('home')}
        />
        <NavLinks style={{ direction: isArabic ? 'rtl' : 'ltr' }}>
          <NavLink 
            onClick={() => scrollToSection('home')} 
            isDarkMode={isDarkMode}
          >
            {i18n.t('nav.home')}
          </NavLink>
          <NavLink 
            onClick={() => scrollToSection('about')} 
            isDarkMode={isDarkMode}
          >
            {i18n.t('nav.about')}
          </NavLink>
          <NavLink 
            onClick={() => scrollToSection('services')} 
            isDarkMode={isDarkMode}
          >
            {i18n.t('nav.services')}
          </NavLink>
          <NavLink 
            onClick={() => scrollToSection('contact')} 
            isDarkMode={isDarkMode}
          >
            {i18n.t('nav.contact')}
          </NavLink>
        </NavLinks>
        <RightSection>
          <LanguageSelector>
            <LangButton
              active={i18n.language === 'tr'}
              onClick={() => handleLanguageChange('tr')}
              isDarkMode={isDarkMode}
            >
              TR
            </LangButton>
            <LangButton
              active={i18n.language === 'en'}
              onClick={() => handleLanguageChange('en')}
              isDarkMode={isDarkMode}
            >
              EN
            </LangButton>
            <LangButton
              active={i18n.language === 'ar'}
              onClick={() => handleLanguageChange('ar')}
              isDarkMode={isDarkMode}
            >
              AR
            </LangButton>
          </LanguageSelector>
          <ThemeToggle onClick={toggleTheme} isDarkMode={isDarkMode}>
            {isDarkMode ? <FiSun size={24} /> : <FiMoon size={24} />}
          </ThemeToggle>
        </RightSection>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;