import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import Header from './components/Header/header';
import Hero from './components/Hero/Hero';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import './App.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import styled from 'styled-components';
import { useTheme } from './context/ThemeContext';
import About from './components/About/About';
import './styles/main.css';

const AppContainer = styled.div`
  min-height: 100vh;
  background: ${props => props.isDarkMode ? '#1a1a2e' : '#ffffff'};
  color: ${props => props.isDarkMode ? '#ffffff' : '#1a1a2e'};
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow-x: hidden;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => props.isDarkMode ? '#1a1a2e' : '#ffffff'};
    transform: ${props => props.isDarkMode ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;
  }

  &::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at ${props => props.mousePosition?.x}px ${props => props.mousePosition?.y}px,
      rgba(0, 191, 165, 0.1) 0%,
      transparent 50%
    );
    opacity: ${props => props.isDarkMode ? 1 : 0};
    transition: opacity 0.6s ease;
    pointer-events: none;
    z-index: -1;
  }
`;

const AppContent = () => {
  const { isDarkMode } = useTheme();
  const [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setMousePosition({
      x: e.clientX,
      y: e.clientY
    });
  };

  return (
    <AppContainer 
      isDarkMode={isDarkMode} 
      mousePosition={mousePosition}
      onMouseMove={handleMouseMove}
    >
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <Hero />
            <About />
            <Services />
            <Contact />
          </>
        } />
      </Routes>
    </AppContainer>
  );
};

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider>
        <Router basename="/">
          <AppContent />
        </Router>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;