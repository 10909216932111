import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FiMail, FiPhone, FiMapPin } from 'react-icons/fi';

const ContactSection = styled.section`
  padding: 80px 0;
  background: #1a1a2e;
  color: white;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 50px;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.2rem;

  svg {
    color: #00bfa5;
    font-size: 1.5rem;
  }
`;

const Contact = () => {
  const { i18n } = useTranslation();

  return (
    <ContactSection id="contact">
      <Container>
        <Title>{i18n.t('contact.title')}</Title>
        <ContactInfo>
          <ContactItem>
            <FiMapPin />
            <p>Al Asayel St,
              Sobha Ivory-2,
              No:606
              Dubai/UAE </p>

          </ContactItem>
          <ContactItem>
            <FiMail />
            <p>info@neoplussoftware.com</p>
          </ContactItem>
          <ContactItem>
            <FiPhone />
            <p>+971586211306</p>
          </ContactItem>
        </ContactInfo>
      </Container>
    </ContactSection>
  );
};

export default Contact;